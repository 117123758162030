export default [
  {
    title: 'Productos',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Listado Productos',
        route: 'categoriesList',
      },
    ],
  },
]
