<template>
  <div
    v-if="isLogin"
    class="navbar-container d-flex content align-items-center"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale v-if="isRoleDocumental" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
            <span class="user-status">
              {{ roleDisplayName }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('Mi perfil') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Cerrar sesión') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { isUserLoggedIn } from '@/auth/utils'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    Locale,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      avatar: 'auth/getAvatar',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    userName() {
      return this.currentUser?.name || ''
    },
    role() {
      if (!this.currentUser?.roles || this.currentUser.roles.length === 0) {
        return null
      }

      return this.currentUser.roles[0]
    },
    roleDisplayName() {
      return this.role?.display_name[this.currentLanguage] || ''
    },
    isRoleDocumental() {
      return ['documental', 'user'].includes(this.role?.name)
    },
    isLogin() {
      return isUserLoggedIn()
    },
  },
  methods: {
    ...mapActions('auth', { logout: 'logout' }),
  },
}
</script>
