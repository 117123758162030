export default [
  {
    title: 'Pedidos',
    route: 'orders',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Usuarios',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Clientes',
    route: 'clients',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Obras',
    route: 'projects',
    icon: 'HomeIcon',
  },
  {
    title: 'Equipos',
    route: 'assets',
    icon: 'CpuIcon',
  },
  {
    title: 'Incidencias',
    route: 'incidences',
    icon: 'AlertTriangleIcon',
  },
  {
    title: 'Mantenimiento',
    route: 'maintenances',
    icon: 'ToolIcon',
  },
  {
    title: 'Productos',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Listado Productos',
        route: 'categoriesList',
      },
      {
        title: 'Categorias Productos',
        route: 'categories',
      },
    ],
  },
  {
    title: 'Configuración',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Usuarios internos',
        route: 'internals',
      },
      {
        title: 'Datos empresa',
        route: 'config',
      },
      {
        title: 'Precios envío',
        route: 'provinces',
      },
      {
        title: 'Formas de pago',
        route: 'payments',
      },
      {
        title: 'Tipos de empresa',
        route: 'companyTypes',
      },
    ],
  },
]
