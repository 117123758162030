export default [
  {
    title: 'Obras',
    route: 'projects',
    icon: 'HomeIcon',
  },
  {
    title: 'Equipos',
    route: 'assets',
    icon: 'CpuIcon',
  },
  {
    title: 'Incidencias',
    route: 'incidences',
    icon: 'AlertTriangleIcon',
  },
  {
    title: 'Mantenimiento',
    route: 'maintenances',
    icon: 'ToolIcon',
  },
]
