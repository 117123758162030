export default [
  {
    title: 'Pedidos',
    route: 'orders',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Productos',
    route: 'categoriesList',
    icon: 'PackageIcon',
  },
  {
    title: 'Empresa',
    route: 'currentClient',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Mi perfil',
    route: 'profile',
    icon: 'UserIcon',
  },
]
